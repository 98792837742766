import { compareTwoValues, convertDate } from "../utils/main";

export const emptyPatient = {
  id: null,
  age: 0,
  sex: null,
  race: null,
  ethnicity: null,
  procedureDate: new Date("0001-01-01"),
  attributes: [],
  adverseEvents: null,
  deviceDeficient: null,
  performance: null,
  clinicalBenefit: null,
  targetLocation: null,
  indicationForUse: null,
  procedureCodes: [],
  diagnosisCodes: [],
  encounters: [],
  patientSummary: {
    id: 0,
    age: 0,
    sex: "Undefined",
    procedureDate: new Date("0001-01-01"),
  },
  reasons: [],
};

export function patientActivityLogs(logs) {
  const { stringToDate } = convertDate;
  return logs.map((log) => {
    return {
      ...log,
      createdAt: stringToDate(log.createdAt),
    };
  });
}

export function sortEncounterCodes(sort, sortBy, codes) {
  const dataSortedAsc = codes.sort((a, b) => {
    return compareTwoValues(a[sortBy], b[sortBy]);
  });
  if (sort === "asc") {
    return dataSortedAsc;
  }
  return dataSortedAsc.reverse();
}
