import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, Link } from "react-router-dom";
import {
  TextField,
  Button,
  Typography,
  Link as MuiLink,
  CircularProgress,
  FormHelperText,
} from "@mui/material";
import { debouncer } from "../../utils/main";
import { signUpEmailRegex } from "../../utils/regex";
import { EmailDomain } from "../../services/EmailDomain";
import { Registration } from "../../services/Registration";

export default function SignUp() {
  const navigate = useNavigate();
  const [signUpState, setSignUpState] = useState({
    firstName: "",
    lastName: "",
    title: "",
    email: "",
    companyName: "",
  });
  const [confirmEmail, setConfirmEmail] = useState("");
  const [emailMessage, setEmailMessage] = useState("");
  const [confirmEmailMessage, setConfirmEmailMessage] = useState("");
  const [checkingDomain, setCheckingDomain] = useState(false);
  const [showCompanyName, setShowCompanyName] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  function submit() {
    setSubmitted(true);
    if (
      !signUpState.firstName.length > 0 ||
      !signUpState.lastName.length > 0 ||
      !signUpState.title.length > 0 ||
      signUpState.email !== confirmEmail
    ) {
      // don't submit
    } else {
      // submit
      Registration(signUpState)
        .then((_) => {
          if (!showCompanyName) {
            navigate("/confirmation");
          } else {
            navigate("/confirmation_with_companyName");
          }
        })
        .catch((error) => {
          if (error === "409") {
            setConfirmEmailMessage(
              <>
                An account with the email address <b>{signUpState.email}</b>{" "}
                already exists. Please log in <a href="/login">here</a>.
              </>
            );
          }
        });
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    submit();
  };

  const isDomainValid = useCallback(() => {
    const regex = new RegExp(`(?<=@).*`, "g");

    EmailDomain(signUpState.email?.match(regex)[0])
      .then((response) => {
        if (response === 200) {
          setConfirmEmailMessage("");
          setCheckingDomain(false);
          setShowCompanyName(false);
        }
        if (response === "404") {
          setConfirmEmailMessage(
            <>
              The email domain <b>{signUpState.email.split("@")[1]}</b> is not
              from a company we know about. Please continue by entering your
              company name below and we will contact you as soon as possible.
            </>
          );
          setShowCompanyName(true);
        }
        setCheckingDomain(false);
      })
      .catch(() => undefined);
  }, [signUpState.email]);

  useEffect(() => {
    if (signUpState.email.length > 0) {
      if (!signUpEmailRegex.test(signUpState.email)) {
        setEmailMessage("E-mail not in valid format");
      } else {
        setEmailMessage("");
      }
      if (confirmEmail.length > 0) {
        if (signUpState.email !== confirmEmail) {
          setConfirmEmailMessage(
            "Please make sure the two email addresses above match."
          );
        } else {
          setConfirmEmailMessage("");
          setCheckingDomain(true);
          isDomainValid();
        }
      }
    }
  }, [signUpState.email, confirmEmail, isDomainValid]);

  return (
    <div className="authentication">
      <form onSubmit={handleSubmit} className="login-form">
        <div>
          <Typography variant="h6" gutterBottom>
            Sign Up
          </Typography>
          <Typography variant="body2" gutterBottom>
            Please use your company email address to sign up for a preview
            account.
          </Typography>
        </div>
        <TextField
          id={"name"}
          label="First Name"
          onChange={debouncer(
            (evt) =>
              setSignUpState((signUpState) => ({
                ...signUpState,
                firstName: evt.target.value.trim(),
              })),
            500
          )}
          inputProps={{ "data-testid": "SignUp-first-name" }}
          error={!signUpState.firstName.length > 0 && submitted}
          helperText={
            !signUpState.firstName.length > 0 &&
            submitted &&
            "Please enter your first name."
          }
          fullWidth
        />
        <TextField
          id="lastName"
          label="Last Name"
          onChange={debouncer(
            (evt) =>
              setSignUpState((signUpState) => ({
                ...signUpState,
                lastName: evt.target.value.trim(),
              })),
            500
          )}
          inputProps={{ "data-testid": "SignUp-last-name" }}
          error={!signUpState.lastName.length > 0 && submitted}
          helperText={
            !signUpState.lastName.length > 0 &&
            submitted &&
            "Please enter your last name."
          }
          fullWidth
        />
        <TextField
          id="title"
          label="Work Title"
          onChange={debouncer(
            (evt) =>
              setSignUpState((signUpState) => ({
                ...signUpState,
                title: evt.target.value.trim(),
              })),
            500
          )}
          inputProps={{ "data-testid": "SignUp-title" }}
          error={!signUpState.title.length > 0 && submitted}
          helperText={
            !signUpState.title.length > 0 &&
            submitted &&
            "Please enter your title"
          }
          fullWidth
        />
        <TextField
          id="email"
          label="Company Email"
          type="email"
          onChange={debouncer(
            (evt) =>
              setSignUpState((signUpState) => ({
                ...signUpState,
                email: evt.target.value.trim().toLowerCase(),
              })),
            500
          )}
          inputProps={{ "data-testid": "SignUp-company-email" }}
          error={emailMessage.length > 0}
          helperText={emailMessage}
          fullWidth
        />
        <TextField
          id="emailConfirmation"
          className={"error-409"}
          label="Confirm Company Email"
          type="email"
          error={confirmEmailMessage !== ""}
          onChange={debouncer(
            (evt) => setConfirmEmail(evt.target.value.trim().toLowerCase()),
            500
          )}
          inputProps={{ "data-testid": "SignUp-company-email-confirmation" }}
          fullWidth
          helperText={confirmEmailMessage !== "" && confirmEmailMessage}
        />
        {checkingDomain && (
          <div className="signup-loading">
            <CircularProgress />
            <FormHelperText>
              We are checking our database for the email domain{" "}
              <b>{signUpState.email.split("@")[1]}</b>
            </FormHelperText>
          </div>
        )}
        {showCompanyName && (
          <TextField
            id="companyName"
            label="Company Name"
            error={showCompanyName && signUpState.companyName === ""}
            onChange={debouncer(
              (evt) =>
                setSignUpState((signUpState) => ({
                  ...signUpState,
                  companyName: evt.target.value.trim(),
                })),
              500
            )}
            inputProps={{ "data-testid": "SignUp-companyName" }}
            fullWidth
            helperText={
              showCompanyName &&
              signUpState.companyName === "" &&
              "Please write your Company Name"
            }
          />
        )}
        <Button
          variant="contained"
          color="primary"
          type="submit"
          data-testid="SignUp-submit"
        >
          Submit
        </Button>
      </form>
      <div className="login-footer-link">
        <Typography variant="body2">Already signed up?</Typography>
        <MuiLink component={Link} variant="body2" to="/login">
          Log in
        </MuiLink>
      </div>
    </div>
  );
}
