import {
  Breadcrumbs,
  Button,
  Grid,
  LinearProgress,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../../../shared/Footer";
import { useFetch } from "../../../../services/hooks/useFetch";
import { deviceApiPrefix, userApiPrefix } from "../../../../utils/main";
import {
  DataGridPremium,
  GridActionsCellItem,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-premium";
import { ManageAccounts, LockOpen, Lock, PersonAdd } from "@mui/icons-material";
import UserManagementModal from "./UserManagementModal";

export default function UserSettingsPage() {
  function CustomToolBar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <Button
          color="primary"
          startIcon={<PersonAdd />}
          onClick={() => handleEditClick({})}
        >
          Add user
        </Button>
        <GridToolbarQuickFilter
          debounceMs={600}
          placeholder="Search email or name"
        />
      </GridToolbarContainer>
    );
  }

  const {
    data: companies,
    fetch: fetchCompanies,
    isLoading: companiesLoading,
    error: companiesError,
  } = useFetch();
  const {
    data: usersData,
    fetch: fetchUsers,
    isLoading: usersLoading,
  } = useFetch();
  const { update: saveUser } = useFetch();

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 0,
  });
  const [sortModel, setSortModel] = useState([]);
  const [filter, setFilter] = useState("");
  const [rows, setRows] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [shouldFetchUsers, setShouldFetchUsers] = useState(true);
  const [editing, setEditing] = useState(false);
  const [userEditing, setUserEditing] = useState({});

  const handleClose = () => {
    setEditing(false);
    setUserEditing({});
  };

  const handleEditClick = (selectedUser) => {
    setEditing(true);
    setUserEditing(selectedUser);
  };

  const handleSaveClick = (updatedUser, newCompany, oldCompany, newRoles) => {
    // save user changes
    saveUser(
      `${userApiPrefix}/user/`,
      {
        user: updatedUser,
        companyId: newCompany.id,
        oldCompanyId: oldCompany ? oldCompany.id : null,
        roles: newRoles,
      },
      "POST",
      undefined,
      async () => {
        setEditing(false);
        setUserEditing({});
        setShouldFetchUsers(true);
      }
    );
  };

  const userDataTableFields = [
    {
      field: "active",
      headerName: "Active",
      width: 100,
      align: "center",
      renderCell: (params) => {
        return params.row.active ? (
          <LockOpen fontSize="small" color="success" />
        ) : (
          <Lock fontSize="small" color="error" />
        );
      },
    },
    { field: "email", headerName: "Email", width: 200 },
    { field: "fullName", headerName: "Name", width: 150 },
    {
      field: "roles",
      headerName: "Roles",
      width: 300,
      renderCell: (params) => {
        const roles = params.row.registrations
          ? params.row.registrations[0]?.roles
          : [];
        return (
          <List sx={{ listStyleType: "disc" }}>
            {roles.map((r) => (
              <ListItem key={crypto.randomUUID()} sx={{ display: "list-item" }}>
                {r}
              </ListItem>
            ))}
          </List>
        );
      },
    },
    {
      field: "group",
      headerName: "Company",
      width: 150,
      valueGetter: (params) => {
        if (params.row.memberships) {
          const company = companies.filter(
            (c) => c.id === params.row.memberships[0]?.groupId
          )[0];
          return company.name;
        }
        return "";
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 75,
      align: "center",
      getActions: ({ row }) => {
        return [
          <GridActionsCellItem
            key={row.id}
            icon={<ManageAccounts fontSize="small" />}
            label="Edit"
            className="textPrimary"
            onClick={() => handleEditClick(row)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  useEffect(() => {
    if (companies.length > 0) {
      setRows(usersData.users);
      setRowCount(usersData.total);
      setShouldFetchUsers(false);
    }
  }, [usersData, companies.length, paginationModel.pageSize]);

  useEffect(() => {
    if (companies?.length > 0 && shouldFetchUsers && !usersLoading) {
      const params = new URLSearchParams();
      params.set("page", paginationModel.page);
      params.set("limit", paginationModel.pageSize);
      if (sortModel.length > 0) {
        params.set(
          "sort",
          sortModel
            .map((s) => `${s.sort === "desc" ? "-" : "+"}${s.field}`)
            .join(",")
        );
      }
      if (filter !== "") {
        params.set("filter", filter);
      }

      fetchUsers(`${userApiPrefix}/user/?${params.toString()}`);
    }
  }, [
    companies,
    fetchUsers,
    usersLoading,
    shouldFetchUsers,
    paginationModel,
    filter,
    sortModel,
  ]);

  useEffect(() => {
    if (!companiesLoading && companies?.length === 0 && !companiesError) {
      fetchCompanies(`${deviceApiPrefix}/companies`);
    }
  }, [fetchCompanies, companies, companiesLoading, companiesError]);

  return (
    <div>
      <Grid
        container
        direction="column"
        justifyContent={"center"}
        alignItems={"center"}
      >
        <br />
        <div>
          <Grid item>
            <Breadcrumbs>
              <Link underline="hover" color="inherit" to={"/"}>
                Global Settings
              </Link>
              <Typography>User Settings</Typography>
            </Breadcrumbs>
          </Grid>
          <br />
          <Grid item>
            <Typography variant="h4" textAlign="left">
              User Settings
            </Typography>
          </Grid>
          <br />
          <Grid item>
            <Typography variant="body2" textAlign="left">
              Information about users.
            </Typography>
          </Grid>
          <br />
          <Grid item>
            <DataGridPremium
              pagination
              paginationMode="server"
              paginationModel={paginationModel}
              onPaginationModelChange={(model) => {
                setPaginationModel(model);
                setShouldFetchUsers(true);
              }}
              sortingMode="server"
              sortModel={sortModel}
              onSortModelChange={(sortModel) => {
                setSortModel(sortModel);
                setShouldFetchUsers(true);
              }}
              filterMode="server"
              onFilterModelChange={(filterModel) => {
                setFilter(filterModel.quickFilterValues.join(" "));
                setShouldFetchUsers(true);
              }}
              rowCount={rowCount || 0}
              loading={usersLoading}
              slots={{
                toolbar: CustomToolBar,
                loadingOverlay: LinearProgress,
              }}
              getRowHeight={() => "auto"}
              density="compact"
              columns={userDataTableFields}
              rows={rows || []}
            />
          </Grid>
          <br />
          <Grid item>
            <Footer />
          </Grid>
          <br />
        </div>
      </Grid>
      {editing && (
        <UserManagementModal
          editMode={!userEditing.id ? "Create" : "Update"}
          user={userEditing}
          companies={companies}
          handleClose={handleClose}
          handleUpdate={handleSaveClick}
        />
      )}
    </div>
  );
}
