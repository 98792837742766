/*
    Note: (PMI-2980) The browser in the bastion reports @media (hover: none) media query, which incorrectly applies
   some mobile styles.  This is most noticeable when hover effects are not correctly applied.
   Several styles have been added to override this issue.
 */
const overrides = {
  components: {
    MuiCardContent: {
      styleOverrides: {
        root: {
          "&:last-child": {
            paddingBottom: "16px"
          }
        }
      }
    }, MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none"
        },
        /* Outlined buttons */
        outlined: {
          "@media (hover: none)": {
            "&:hover": {
              backgroundColor: "rgba(0, 73, 84, 0.04)"
            }
          }
        }
      }
    }, MuiListItemButton: {
      styleOverrides: {
        /* Sidebar button icons */
        root: {
          "@media (hover: none)": {
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.04)"
            }
          }
        }
      }
    }, MuiDataGrid: {
      styleOverrides: {
        /* Table row on hover */
        row: {
          "@media (hover: none)": {
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.04)"
            }
          }
        },
        /* Sort icon on hover */
        sortIcon: {
          "@media (hover: none)": {
            "&:hover": {
              opacity: ".5 !important"
            }
          }
        }
      }
    }, MuiCardActionArea: {
      styleOverrides: {
        /* Patient List */
        root: {
          "@media (hover: none)": {
            "&:hover .MuiCardActionArea-focusHighlight": {
              opacity: ".04"
            }
          }
        },
        /* Table row */
        row: {
          "@media (hover: none)": {
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.04)"
            }
          }
        }
      }
    }, MuiTreeItem2: {
      styleOverrides: {
        /* IMDRF Row */
        content: {
          "@media (hover: none)": {
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.04)"
            }
          }
        }
      }
    }
  }
};

export default overrides;
