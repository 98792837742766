import React, { useState } from "react";
import {
  useNavigate,
  useSearchParams,
  createSearchParams,
  useLocation,
} from "react-router-dom";
import {
  Drawer,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemButton,
  ListItemText,
  Tooltip,
  Paper,
  Divider,
  Collapse,
} from "@mui/material";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

import theme from "../../styles/theme";
import { RequireAuth, useFusionAuth } from "@fusionauth/react-sdk";

export default function NavBar() {
  const settingsPath = "/settings";

  const navigate = useNavigate();
  const { startLogout } = useFusionAuth();

  const [searchParams] = useSearchParams();
  const projectId = searchParams.get("projectId");

  const goProjects = () => {
    navigate("/");
  };

  const goSettings = (settings, global) => {
    var newSearchParams;
    if (!global) {
      newSearchParams = createSearchParams({
        projectId: projectId,
        settings: settings,
      }).toString();
    } else {
      newSearchParams = createSearchParams({
        settings: settings,
      }).toString();
    }

    let previousPath = "";

    if (!window.location.pathname.includes("settings")) {
      previousPath = window.location.pathname + window.location.search;
    } else {
      previousPath = window.location.state?.previousPath;
    }

    navigate(
      {
        pathname: settingsPath,
        search: newSearchParams,
      },
      {
        state: {
          previousPath: previousPath,
        },
      }
    );
  };

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isProjectListOpen, setIsProjectListOpen] = useState(false);
  const [isGlobalListOpen, setIsGlobalListOpen] = useState(false);

  const projectSettingPages = [
    {
      page: "device-details",
      primary: "Device Details",
      secondary: "Manufacturer, GMDN, Description",
    },
    {
      page: "protocol",
      primary: "Protocol",
      secondary: "Dates, Patient Cohort, Criteria",
    },
    {
      page: "project-details",
      primary: "Project Details",
      secondary: "Status, Name, Description",
    },
    {
      page: "prioritization",
      primary: "Prioritization",
      secondary: "Tools to prioritize records",
    },
    {
      page: "additional-data",
      primary: "Additional Data",
      secondary: "Patient Reported Outcomes",
    },
  ];

  const globalSettingPages = [
    {
      page: "company",
      primary: "Company Settings",
      secondary: "Company Name",
    },
    {
      page: "manufacturer-settings",
      primary: "Manufacturer Settings",
      secondary: "Device Mfr. Domain, Device Mfr. Aliasing",
    },
    {
      page: "user",
      primary: "User Settings",
      secondary: "User Management",
    },
  ];

  const location = useLocation();
  const isSettingsPage = () => location.pathname === settingsPath;

  return (
    <>
      <Paper className={"App-sideBar"} elevation={0} square>
        <List className={"sidebar-body"}>
          <div className={"sidebar-logo"}>
            <img
              src={process.env.PUBLIC_URL + "/logo.svg"}
              alt="logo-icon"
              className="logo-icon"
            />
          </div>
          <Tooltip title="All Projects">
            <ListItemButton
              className={"folder-icon"}
              onClick={() => goProjects()}
            >
              <FolderOutlinedIcon color={"primary"} />
            </ListItemButton>
          </Tooltip>
          {projectId != null && (
            <Tooltip title="Project Settings">
              <ListItemButton
                className="settings-icon"
                onClick={() => {
                  setIsDrawerOpen(true);
                  setIsProjectListOpen(true);
                }}
              >
                <SettingsOutlinedIcon color={"primary"} />
              </ListItemButton>
            </Tooltip>
          )}
          <RequireAuth withRole={"ADMIN"}>
            <Tooltip title="Global Settings">
              <ListItemButton
                className="settings-icon"
                onClick={() => {
                  setIsDrawerOpen(true);
                  setIsGlobalListOpen(true);
                }}
              >
                <SettingsOutlinedIcon color={"primary"} />
              </ListItemButton>
            </Tooltip>
          </RequireAuth>
        </List>
        <div className={"general-drawer-footer"}>
          <Tooltip placement={"right"} title="Logout">
            <ListItemButton onClick={() => startLogout()}>
              <LogoutIcon style={{ color: theme.palette.action.active }} />
            </ListItemButton>
          </Tooltip>
          <Tooltip placement={"right"} title="Expand">
            <ListItemButton onClick={() => setIsDrawerOpen(true)}>
              <ChevronRightOutlinedIcon
                style={{ color: theme.palette.action.active }}
              />
            </ListItemButton>
          </Tooltip>
        </div>
        <Drawer
          className={"drawer"}
          anchor="left"
          open={isDrawerOpen}
          slotProps={
            isSettingsPage()
              ? {
                  backdrop: {
                    style: { opacity: 0 },
                  },
                }
              : null
          }
          onClose={() => {
            setIsDrawerOpen(false);
            setIsProjectListOpen(false);
          }}
        >
          <Box className={"box"} p={2} role="presentation">
            <List className={"general-drawer-body-box"}>
              <img
                src={process.env.PUBLIC_URL + "/logo-mark.svg"}
                alt="logo"
                className="logo-full"
              />
              <ListItem className={"project-full"}>
                <ListItemButton
                  className="left-align-ListItem"
                  onClick={goProjects}
                >
                  <ListItemIcon>
                    <FolderOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary={"All Projects"} />
                </ListItemButton>
              </ListItem>
              {projectId != null && (
                <>
                  <ListItem className="project-full">
                    <ListItemButton
                      className="left-align-ListItem"
                      onClick={() => setIsProjectListOpen(!isProjectListOpen)}
                    >
                      <ListItemIcon>
                        <SettingsOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText primary="Project Settings" />
                      {isProjectListOpen ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                  </ListItem>
                  <ListItem className="project-full">
                    <Collapse
                      in={isProjectListOpen}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List>
                        {projectSettingPages.map((value, index) => {
                          return (
                            <ListItem
                              className="project-full"
                              key={index}
                              sx={{ paddingTop: 0, paddingBottom: 0 }}
                            >
                              <ListItemButton
                                className="left-align-ListItem"
                                onClick={() => goSettings(value.page, false)}
                              >
                                <ListItemIcon />
                                <ListItemText
                                  primary={value.primary}
                                  secondary={value.secondary}
                                />
                              </ListItemButton>
                            </ListItem>
                          );
                        })}
                      </List>
                    </Collapse>
                  </ListItem>
                </>
              )}
              <RequireAuth withRole={"ADMIN"}>
                <ListItem className="project-full">
                  <ListItemButton
                    className="left-align-ListItem"
                    onClick={() => setIsGlobalListOpen(!isGlobalListOpen)}
                  >
                    <ListItemIcon>
                      <SettingsOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary="Global Settings" />
                    {isGlobalListOpen ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                </ListItem>
                <ListItem className="project-full">
                  <Collapse in={isGlobalListOpen} timeout="auto" unmountOnExit>
                    <List>
                      {globalSettingPages.map((value, index) => {
                        return (
                          <ListItem
                            className="project-full"
                            key={index}
                            sx={{ paddingTop: 0, paddingBottom: 0 }}
                          >
                            <ListItemButton
                              className="left-align-ListItem"
                              onClick={() => goSettings(value.page, true)}
                            >
                              <ListItemIcon />
                              <ListItemText
                                primary={value.primary}
                                secondary={value.secondary}
                              />
                            </ListItemButton>
                          </ListItem>
                        );
                      })}
                    </List>
                  </Collapse>
                </ListItem>
              </RequireAuth>
            </List>
            <List className={"general-drawer-footer-box"}>
              <ListItemButton
                className={"logout"}
                onClick={() => startLogout()}
              >
                <ListItemIcon>
                  <LogoutIcon style={{ color: theme.palette.action.active }} />
                </ListItemIcon>
                <ListItemText primary={"Logout"} />
              </ListItemButton>
              <ListItemButton
                className={"close-nav"}
                onClick={() => setIsDrawerOpen(false)}
              >
                <ListItemIcon style={{ color: theme.palette.action.active }}>
                  <ChevronLeftOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary={"Close Navigation"} />
              </ListItemButton>
            </List>
          </Box>
        </Drawer>
      </Paper>
      <Divider flexItem light orientation={"vertical"} />
    </>
  );
}
