import React from "react";
import { createBrowserHistory } from "history";
import { BrowserRouter } from "react-router-dom";
import AppRouter from "./AppRouter";
import "@fontsource/inter";
import { ThemeProvider } from "@emotion/react";
import theme from "./styles/theme";
import { defaults } from "chart.js";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { FusionAuthProvider } from "@fusionauth/react-sdk";
import { LicenseInfo } from "@mui/x-data-grid-premium";

defaults.font.family = "'DM Sans',Arial, sans-serif";

LicenseInfo.setLicenseKey(
  "8518431e88ce2115f13132a3655da88eTz0xMDc4MzYsRT0xNzczMDE0Mzk5MDAwLFM9cHJlbWl1bSxMTT1zdWJzY3JpcHRpb24sUFY9aW5pdGlhbCxLVj0y"
);

function App() {
  const history = createBrowserHistory();

  const gtmId = window.env.googleTagManagerId;
  if (gtmId && gtmId.startsWith("G-")) {
    const gtmScript = document.createElement("script");
    gtmScript.async = true;
    gtmScript.src = `https://www.googletagmanager.com/gtag/js?id=${gtmId}`;
    document.head.append(gtmScript);
  }

  const fusionAuthConfig = {
    clientId: window.env.authClientId,
    serverUrl: window.env.authServerUrl,
    redirectUri: window.location.origin,
    postLogoutRedirectUri: window.location.origin + "/login",
    shouldAutoRefresh: true,
    shouldAutoFetchUserInfo: true,
    autoRefreshSecondsBeforeExpiry: 30,
  };

  return (
    <div className={"app"}>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <FusionAuthProvider {...fusionAuthConfig}>
            <div className={"App"}>
              <BrowserRouter history={history}>
                <AppRouter />
              </BrowserRouter>
            </div>
          </FusionAuthProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
