import {
  Button,
  Checkbox,
  Chip,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { Box } from "@mui/system";
import { signUpEmailRegex } from "../../../../utils/regex";

const ROLES = ["USER", "CLIENT_ADMIN", "WORKSHOP", "SUPPORT", "ADMIN"];

export default function UserManagementModal({
  editMode,
  user,
  companies,
  handleClose,
  handleUpdate,
}) {
  const [displayErrors, setDisplayErrors] = useState(false);
  const [emailMessage, setEmailMessage] = useState("");
  const [oldCompany] = useState(
    user.memberships
      ? companies.filter((c) => c.id === user.memberships[0]?.groupId)[0]
      : null
  );
  const [currentCompany, setCurrentCompany] = useState(
    user.memberships
      ? companies.filter((c) => c.id === user.memberships[0]?.groupId)[0]
      : null
  );
  const [roles, setRoles] = useState(
    user.registrations ? user.registrations[0].roles : []
  );
  const [email, setEmail] = useState(user.email || "");
  const [firstName, setFirstName] = useState(user.firstName || "");
  const [fullName, setFullName] = useState(user.fullName || "");

  const handleRoleChange = (event) => {
    const {
      target: { value },
    } = event;
    setRoles(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleSubmit = () => {
    let errors = false;
    if (email.length > 0) {
      if (!signUpEmailRegex.test(email)) {
        errors = true;
        setEmailMessage("E-mail not in valid format");
      } else {
        setEmailMessage("");
      }
    }
    if (
      !currentCompany ||
      roles.length === 0 ||
      email === "" ||
      firstName === "" ||
      fullName === ""
    ) {
      errors = true;
    }
    setDisplayErrors(errors);
    if (!errors) {
      user.email = email;
      user.firstName = firstName;
      user.fullName = fullName;
      handleUpdate(user, currentCompany, oldCompany, roles);
    }
  };

  return (
    <Modal open onClose={handleClose}>
      <Stack
        direction="column"
        justifyContent="space-between"
        spacing={1.5}
        divider={<Divider flexItem />}
        className={"order-level-modal"}
        sx={{ bgcolor: "background.paper" }}
      >
        <Typography variant="h6">{editMode} User</Typography>
        <Stack direction="column" spacing={1.5} overflow="auto" height="80%">
          <Typography variant="body2">* Required fields</Typography>
          <Box flexGrow={1}>
            <Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }}>
              <Grid item xs={4} sm={4} md={6}>
                <Autocomplete
                  disablePortal
                  options={companies}
                  getOptionLabel={(option) => {
                    if (option.name) {
                      return option.name;
                    } else {
                      return "";
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      required
                      error={displayErrors && !currentCompany}
                      helperText={
                        displayErrors && !currentCompany
                          ? "This field is required"
                          : ""
                      }
                      {...params}
                      label="Company"
                    />
                  )}
                  value={currentCompany}
                  onChange={(event, value) => {
                    setCurrentCompany(value);
                  }}
                  isOptionEqualToValue={(option, value) => {
                    if (value === null) {
                      return true;
                    }
                    return option.id && value.id
                      ? option.id === value.id
                      : false;
                  }}
                />
              </Grid>
              <Grid item xs={4} sm={4} md={6}>
                <TextField
                  required
                  fullWidth
                  variant="outlined"
                  label="Email"
                  value={email}
                  onChange={(event) => {
                    setEmail(event.target.value);
                  }}
                  error={
                    (displayErrors && email === "") || emailMessage.length > 0
                  }
                  helperText={
                    emailMessage.length > 0
                      ? emailMessage
                      : displayErrors && email === ""
                      ? "This field is required"
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={4} sm={4} md={6}>
                <TextField
                  required
                  fullWidth
                  variant="outlined"
                  label="First Name"
                  value={firstName}
                  onChange={(event) => {
                    setFirstName(event.target.value);
                  }}
                  error={displayErrors && firstName === ""}
                  helperText={
                    displayErrors && firstName === ""
                      ? "This field is required"
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={4} sm={4} md={6}>
                <TextField
                  required
                  fullWidth
                  variant="outlined"
                  label="Full Name"
                  value={fullName}
                  onChange={(event) => {
                    setFullName(event.target.value);
                  }}
                  error={displayErrors && fullName === ""}
                  helperText={
                    displayErrors && fullName === ""
                      ? "This field is required"
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={4} sm={8} md={12}>
                <FormControl
                  fullWidth
                  error={displayErrors && roles.length === 0}
                >
                  <InputLabel id="roles-label" required>
                    Roles
                  </InputLabel>
                  <Select
                    labelId="roles-label"
                    label="Roles"
                    required
                    multiple
                    variant="outlined"
                    value={roles}
                    onChange={handleRoleChange}
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip key={value} label={value} />
                        ))}
                      </Box>
                    )}
                  >
                    {ROLES.map((name) => (
                      <MenuItem key={name} value={name}>
                        <Checkbox checked={roles.includes(name)} />
                        <ListItemText primary={name} />
                      </MenuItem>
                    ))}
                  </Select>

                  <FormHelperText>
                    {displayErrors && roles.length === 0
                      ? "This field is required"
                      : ""}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        </Stack>
        <Stack
          direction="row"
          spacing={1}
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          height="30px"
        >
          <Button size="small" variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <Button size="small" variant="contained" onClick={handleSubmit}>
            {editMode} User
          </Button>
        </Stack>
      </Stack>
    </Modal>
  );
}
