import { Close, OfflineBoltOutlined } from "@mui/icons-material";
import {
  Alert,
  AlertTitle,
  Breadcrumbs,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useFetch } from "../../../services/hooks/useFetch";
import { apiPrefix } from "../../../utils/main";
import theme from "../../../styles/theme";
import Footer from "../../shared/Footer";
import InfoIcon from "@mui/icons-material/Info";
import DOMPurify from "dompurify";

const lf = new Intl.ListFormat("en");
export default function PrioritizationPage({ projectId }) {
  const { fetch: getAllKeywords } = useFetch();
  const { update: bulkUpdateKeywords } = useFetch();

  const { fetch: fetchProject, data: project } = useFetch();
  const { fetch: fetchPreview } = useFetch();

  const [keywords, setKeywords] = useState([]);
  const [updateDetails, setUpdateDetails] = useState("");
  const [previewData, setPreviewData] = useState({});
  const [loadingPreview, setLoadingPreview] = useState([]);

  const [editMode, setEditMode] = useState(false);
  const [refreshRequired, setRefreshRequired] = useState(false);

  const [displayErrors, setDisplayErrors] = useState(false);

  const location = useLocation();

  const getFormattedCategory = function (category) {
    switch (category) {
      case "adverse_event":
        return "Adverse Event";

      case "performance":
        return "Performance";

      case "clinical":
        return "Clinical Benefit";

      default:
        return "";
    }
  };

  useEffect(() => {
    getAllKeywords(`${apiPrefix}/keywords?projectId=${projectId}`, (data) => {
      setKeywords(data);
    });

    if (refreshRequired) setRefreshRequired(false);
  }, [projectId, refreshRequired, getAllKeywords]);

  useEffect(() => {
    fetchProject(`${apiPrefix}/projects/${projectId}`);

    if (refreshRequired) setRefreshRequired(false);
  }, [fetchProject, projectId, refreshRequired]);

  return (
    <div>
      <Grid
        container
        direction="column"
        justifyContent={"center"}
        alignItems={"center"}
      >
        <br />
        <div className="company-settings-item">
          <Grid item>
            <Breadcrumbs>
              {location.state?.previousPath ? (
                <Link
                  underline="hover"
                  color="inherit"
                  to={location.state.previousPath}
                >
                  {project.name}
                </Link>
              ) : (
                <Link
                  underline="hover"
                  color="inherit"
                  to={`/details?projectId=${projectId}`}
                >
                  {project.name}
                </Link>
              )}
              <Link
                underline="hover"
                color="inherit"
                to={`/details?projectId=${projectId}`}
              >
                Project Settings
              </Link>
              <Typography>Prioritization</Typography>
            </Breadcrumbs>
          </Grid>
          <br />
          <Grid item>
            <Typography variant="h4">Prioritization</Typography>
          </Grid>
          <br />
          <Grid
            item
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{ backgroundColor: "rgba(0, 180, 215, 0.1)" }}
          >
            <Alert className="prioritation-info-text" severity="info">
              <AlertTitle>How 3Aware Prioritization Works</AlertTitle>
              Using your selections below, 3Aware will assign a priority score
              to clinical notes and patient visits. Records with high priority
              will be displayed with this icon:
              <OfflineBoltOutlined
                className="icon"
                size="small"
                sx={{ color: theme.palette.info.main }}
              />
            </Alert>
          </Grid>
          {/* <br />
          <Grid item>
            <Typography variant="h6">Important Clinical Note Types</Typography>
            <Typography variant="caption">
              Select the types of clinical notes that you would like to
              prioritize.
            </Typography>
          </Grid>
          <br />
          <Grid item>
            <InputLabel shrink htmlFor="select-clinical-notes">
              Clinical Note Types
            </InputLabel>
            <Select
              multiple
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value.id} label={value.modelNumber} />
                  ))}
                </Box>
              )}
              inputProps={{
                id: "select-clinical-notes",
              }}
              sx={{ width: "50ch" }}
            ></Select>
          </Grid> */}
          <br />
          <Grid item>
            <Typography variant="h6">Important Keywords</Typography>
            <Typography variant="body2">
              Project-level keywords for 3Aware to use when prioritizing each
              patient’s visits and clinical notes. Each keyword is assigned a
              category based on the corresponding outcome measure.
            </Typography>
          </Grid>
          <br />
          <Grid item>
            <Typography variant="body2">* Required fields</Typography>
          </Grid>
          <br />
          <Grid item>
            <Stack
              direction="column"
              divider={
                <Divider sx={{ marginTop: "1rem", marginBottom: "1rem" }} />
              }
            >
              {keywords.map((value, index) => {
                if (value.forDelete) {
                  return <div key={`row-${index}`}></div>;
                }

                return editMode ? (
                  <Grid
                    container
                    direction="row"
                    justifyContent={"center"}
                    alignItems={"center"}
                    key={`row-${index}`}
                    rowSpacing={1.5}
                    columnSpacing={2}
                  >
                    <Grid item xs={5}>
                      <TextField
                        required
                        fullWidth
                        label="Keyword"
                        value={value.phrase}
                        error={displayErrors && value.phrase === ""}
                        helperText={
                          displayErrors && value.phrase === ""
                            ? "This field is required"
                            : ""
                        }
                        onChange={(event) => {
                          let localKeywords = structuredClone(keywords);
                          localKeywords[index].phrase = event.target.value;
                          setKeywords(localKeywords);
                          setPreviewData((prevItems) => {
                            const { [index]: _, ...updatedItems } = prevItems;
                            return updatedItems;
                          });
                        }}
                        InputProps={{ readOnly: !editMode }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl
                        error={displayErrors && value.category === ""}
                        fullWidth
                      >
                        <InputLabel shrink htmlFor={"select-category-" + index}>
                          Category
                        </InputLabel>
                        <Select
                          label="Category"
                          value={value.category}
                          onChange={(event) => {
                            let localKeywords = structuredClone(keywords);
                            localKeywords[index].category = event.target.value;
                            setKeywords(localKeywords);
                            setPreviewData((prevItems) => {
                              const { [index]: _, ...updatedItems } = prevItems;
                              return updatedItems;
                            });
                          }}
                          inputProps={{ id: "select-category-" + index }}
                          readOnly={!editMode}
                          variant="outlined"
                        >
                          <MenuItem value="adverse_event">
                            Adverse Event
                          </MenuItem>
                          <MenuItem value="performance">Performance</MenuItem>
                          <MenuItem value="clinical">Clinical Benefit</MenuItem>
                        </Select>
                        {displayErrors && value.category === "" && (
                          <FormHelperText>
                            This field is required
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={2}
                      spacing={0.5}
                      direction="row"
                      alignItems="center"
                    >
                      <Grid item>
                        <ToggleButtonGroup
                          color="primary"
                          value={`${value.exact}`}
                          exclusive
                          onChange={(event, newMatchMode) => {
                            let localKeywords = structuredClone(keywords);
                            localKeywords[index].exact =
                              newMatchMode === "true";
                            setKeywords(localKeywords);
                            setPreviewData((prevItems) => {
                              const { [index]: _, ...updatedItems } = prevItems;
                              return updatedItems;
                            });
                          }}
                          aria-label="Match Mode"
                        >
                          <ToggleButton value="true">Exact</ToggleButton>
                          <ToggleButton value="false">Fuzzy</ToggleButton>
                        </ToggleButtonGroup>
                      </Grid>
                      <Grid item display="flex">
                        <Tooltip
                          title="Exact match will only prioritize visits and notes containing this exact keyword. Fuzzy match will also prioritize slight variations of this keyword."
                          placement="top-end"
                          arrow
                        >
                          <InfoIcon
                            size="small"
                            sx={{ color: theme.palette.info.main }}
                          />
                        </Tooltip>
                      </Grid>
                    </Grid>
                    <Grid item xs={1}>
                      <IconButton
                        onClick={() => {
                          let localKeywords = structuredClone(keywords);
                          localKeywords[index].forDelete = true;
                          setKeywords(localKeywords);
                        }}
                      >
                        <Close sx={{ color: "rgba(161, 38, 16, 1)" }} />
                      </IconButton>
                    </Grid>
                    <Grid item xs={1}>
                      <Button
                        variant="contained"
                        disabled={!value.phrase || !!previewData[index]}
                        onClick={() => {
                          setLoadingPreview([...loadingPreview, index]);
                          fetchPreview(
                            `${apiPrefix}/keywords/counts?projectId=${projectId}&phrase=${value.phrase}&exact=${value.exact}`,
                            (data) => {
                              setPreviewData({ ...previewData, [index]: data });
                              setLoadingPreview(
                                loadingPreview.filter((i) => i !== index)
                              );
                            }
                          );
                        }}
                      >
                        Preview
                      </Button>
                    </Grid>
                    <Grid item xs={11}>
                      {loadingPreview.includes(index) && <CircularProgress />}
                      {!!previewData[index] && (
                        <Typography
                          variant="body2"
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(
                              value.exact
                                ? `<strong>Exact</strong> match for <highlight>${value.phrase}</highlight> occurs <strong>${previewData[index].wordCount}</strong> times in <strong>${previewData[index].documentCount}</strong> documents in this project.`
                                : `<strong>Fuzzy</strong> match for ${
                                    value.phrase
                                  } will match ${lf.format(
                                    previewData[index].matchedWords.slice(0, 5)
                                  )} <strong>${
                                    previewData[index].wordCount
                                  }</strong> times in <strong>${
                                    previewData[index].documentCount
                                  }</strong> documents in this project.`,
                              {
                                ALLOWED_TAGS: ["strong", "highlight"],
                              }
                            ),
                          }}
                        />
                      )}
                    </Grid>
                  </Grid>
                ) : (
                  <Grid
                    item
                    container
                    direction="row"
                    justifyContent={"center"}
                    alignItems={"flex-end"}
                    key={`row-${index}`}
                    columnSpacing={4}
                    rowSpacing={1.5}
                  >
                    <Grid item xs={6}>
                      <Typography variant="caption">Keyword *</Typography>
                      <Typography variant="body1" sx={{ width: "20ch" }}>
                        {value.phrase}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="caption">Category *</Typography>
                      <Typography variant="body1" sx={{ width: "50ch" }}>
                        {getFormattedCategory(value.category)}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2">
                        {value.exact
                          ? "Only exact matches"
                          : "Slight variations included"}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} />
                  </Grid>
                );
              })}
            </Stack>
          </Grid>
          <br />
          {!editMode || (
            <Grid item>
              <Button
                variant="contained"
                onClick={() => {
                  let localKeywords = structuredClone(keywords);
                  localKeywords.push({
                    phrase: "",
                    category: "",
                    projectId: projectId,
                    exact: true,
                  });
                  setKeywords(localKeywords);
                }}
              >
                Add Keyword
              </Button>
            </Grid>
          )}
          {editMode && (
            <>
              <br />
              <Grid item>
                <TextField
                  required
                  variant="outlined"
                  label="Note/Reason for Updating the Prioritization"
                  className="settings-text-input"
                  sx={{ width: "50ch" }}
                  value={updateDetails}
                  error={displayErrors && updateDetails === ""}
                  helperText={
                    displayErrors && updateDetails === ""
                      ? "This field is required"
                      : ""
                  }
                  onChange={(event) => {
                    setUpdateDetails(event.target.value);
                  }}
                />
              </Grid>
              <br />
            </>
          )}
          <Grid container item justifyContent={"right"}>
            {(editMode && (
              <>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setRefreshRequired(true);
                    setUpdateDetails("");
                    setDisplayErrors(false);
                    setEditMode(false);
                    setPreviewData({});
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  className="update-button"
                  sx={{ marginLeft: "5px" }}
                  onClick={() => {
                    let missingValues = false;

                    for (let i = 0; i < keywords.length; i++) {
                      if (
                        keywords[i].phrase === "" ||
                        keywords[i].category === ""
                      ) {
                        missingValues = true;
                        break;
                      }
                    }

                    if (updateDetails === "") {
                      missingValues = true;
                    }

                    if (missingValues) {
                      setDisplayErrors(true);
                      return;
                    }

                    bulkUpdateKeywords(
                      `${apiPrefix}/keywords/bulk`,
                      {
                        reason: updateDetails,
                        keywords: keywords,
                      },
                      "PUT"
                    ).then(() => {
                      setEditMode(false);
                      setRefreshRequired(true);
                      setUpdateDetails("");
                      setPreviewData({});
                    });
                  }}
                >
                  Update
                </Button>
              </>
            )) || (
              <Button variant="contained" onClick={() => setEditMode(true)}>
                Edit
              </Button>
            )}
          </Grid>
          <br />
          <Footer />
        </div>
      </Grid>
      <br />
    </div>
  );
}
