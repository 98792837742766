import React, {
  useState,
  useCallback,
  useEffect,
  createContext,
  useRef,
} from "react";
import { useSearchParams } from "react-router-dom";
import { Divider } from "@mui/material";
import NavBar from "../shared/NavBar";
import Footer from "../shared/Footer";
import { projectsPrefix } from "../../services/ProjectsServices";
import Overview from "./overview/Overview";
import PreviewModeOverview from "./PreviewModeOverview";
import ProjectHeader from "../shared/ProjectHeader";
import { apiPrefix, convertDate, projectVersions } from "../../utils/main";
import Patients from "./patients/Patients";
import { useFetch } from "../../services/hooks/useFetch";
import { emptyPatient } from "../../models/patient";
import { ATTRIBUTE_TYPES } from "./patients/categorize/CategorizeUtils";

export const CurrentPatient = createContext(null);
export const CurrentProject = createContext(null);

export default function ProjectDetails() {
  const [searchParams] = useSearchParams();
  const patientId = searchParams.get("patientId");
  const projectId = searchParams.get("projectId");
  // values for CurrentPatient useContext work
  const [isProblemsUpdated, setIsProblemsUpdated] = useState(false);
  const [isOtherDevicesUsedUpdated, setIsOtherDevicesUsedUpdated] =
    useState(false);
  const [isPromsUpdated, setIsPromsUpdated] = useState(false);
  const [isDiagnosisCodesUpdated, setIsDiagnosisCodesUpdated] = useState(false);
  const [isProcedureCodesUpdated, setIsProcedureCodesUpdated] = useState(false);
  const [encounters, setEncounters] = useState([]);
  const [isEncountersUpdated, setIsEncountersUpdated] = useState(false);
  const [indexEncounter, setIndexEncounter] = useState([]);
  const [isIndexEncounterUpdated, setIsIndexEncounterUpdated] = useState(false);
  const [patient, setPatient] = useState(emptyPatient);
  const [patientLoading, setPatientLoading] = useState(false);
  const [patientProblems, setPatientProblems] = useState([]);
  const [otherDevicesUsed, setOtherDevicesUsed] = useState([]);
  const [diagnosisCodes, setDiagnosisCodes] = useState([]);
  const [procedureCodes, setProcedureCodes] = useState([]);
  const [orderLevelDocuments, setOrderLevelDocuments] = useState(null);
  const [showCodeUpdateFailureAlert, setShowCodeUpdateFailureAlert] =
    useState(false);
  const [showPollingTimeoutAlert, setShowPollingTimeoutAlert] = useState(false);
  const [disableRefineButton, setDisableRefineButton] = useState(true);
  const pollTimer = useRef();

  const resetPatientFlags = useCallback(() => {
    setIsProblemsUpdated(false);
    setPatientProblems([]);

    setIsOtherDevicesUsedUpdated(false);
    setOtherDevicesUsed([]);

    setIsDiagnosisCodesUpdated(false);
    setDiagnosisCodes([]);

    setIsProcedureCodesUpdated(false);
    setProcedureCodes([]);

    setIsEncountersUpdated(false);
    setEncounters([]);

    setIsIndexEncounterUpdated(false);
    setIndexEncounter(null);

    setIsPromsUpdated(false);
    setOrderLevelDocuments(null);
  }, []);

  const [selectedTab, setSelectedTab] = useState(patientId ? 1 : 0);

  const {
    data: project,
    fetch: fetchProject,
    update: updateProject,
    isLoading: projectLoading,
  } = useFetch();
  const {
    data: deviceData,
    fetch: searchDevice,
    error: deviceSearchError,
    isLoading: deviceLoading,
    reset: resetDevice,
  } = useFetch();
  const {
    data: summaryData,
    fetch: fetchSummaries,
    isLoading: summariesLoading,
    status: summariesStatus,
  } = useFetch();
  const [deviceSearched, setDeviceSearched] = useState({});
  const [patientSummaries, setPatientSummaries] = useState([]);

  const disabled =
    summariesLoading ||
    project.patientCount === 0 ||
    project.version === projectVersions.previewMode;
  const [patientMaxMode, setPatientMaxMode] = useState(false);

  useEffect(() => {
    if (patientLoading) {
      resetPatientFlags();
    }
  }, [patientLoading, resetPatientFlags]);

  function getImdrfEnabled() {
    if (!project || !project.projectSettings) return true;
    const imdrfSetting = project.projectSettings?.find(
      (s) => s.name === "DISABLE_IMDRF"
    );
    return (
      !imdrfSetting ||
      imdrfSetting.value === "" ||
      imdrfSetting.value === "FALSE"
    );
  }

  const fetchProjectData = useCallback(async () => {
    fetchProject(`${projectsPrefix}/${projectId}`, (data) => {
      const startDate = convertDate.stringToDate(data.startDate);
      const endDate = convertDate.stringToDate(data.endDate);
      const createdAt = convertDate.stringToDate(data.createdAt);
      const modifiedAt = convertDate.stringToDate(data.modifiedAt);
      const version =
        data.statusName === "Preview Mode"
          ? projectVersions.previewMode
          : projectVersions.premium;
      return { ...data, startDate, endDate, createdAt, modifiedAt, version };
    });
    resetDevice();
    setDeviceSearched({});
  }, [fetchProject, projectId, resetDevice]);

  useEffect(() => {
    void fetchProjectData();
  }, [projectId, fetchProjectData]);

  useEffect(() => {
    if (
      !projectLoading &&
      !!project.id &&
      project.version === projectVersions.premium &&
      !deviceLoading &&
      !!deviceSearched.totalPatients
    ) {
      fetchSummaries(`${projectsPrefix}/${project.id}/patients`, (data) => {
        return data;
      });
    }
  }, [
    deviceLoading,
    deviceSearched.totalPatients,
    fetchSummaries,
    projectLoading,
    project,
  ]);

  useEffect(() => {
    if (!summariesLoading && !!summaryData.length > 0) {
      setPatientSummaries(summaryData);
    }
  }, [summariesLoading, summaryData, setPatientSummaries]);

  const pollApiEndpoint = useCallback(
    async (projectId) => {
      return new Promise((resolve, reject) => {
        searchDevice(`${apiPrefix}/projects/${projectId}/summary`, (data) => {
          if (data) {
            resolve(data);
          } else {
            reject(new Error("No data returned"));
          }
        });
      });
    },
    [searchDevice]
  );

  function setPatientAttributes(data) {
    let newPatient = { ...patient, attributes: data };
    setPatient(newPatient);

    //This is to show the categorization icons in the patient list
    let newSummaries = [];
    patientSummaries.forEach((s) => {
      if (s.id === newPatient.id) {
        newSummaries.push(updatePatientSummary(s, data));
      } else {
        newSummaries.push(s);
      }
    });
    setPatientSummaries(newSummaries);
  }

  function updateEncounters(data) {
    let newEncounters = [];
    encounters.forEach((e) => {
      if (e.id === data.id) {
        newEncounters.push(data);
      } else {
        newEncounters.push(e);
      }
    });
    setEncounters(newEncounters);
  }

  function updatePatientSummaries(data) {
    let newSummaries = [];
    patientSummaries.forEach((s) => {
      if (s.id === data.id) {
        newSummaries.push(data);
      } else {
        newSummaries.push(s);
      }
    });
    setPatientSummaries(newSummaries);
  }

  function updatePatientSummary(summary, attributes) {
    let aeList = attributes.filter(
      (a) => a.attributeTypeId === ATTRIBUTE_TYPES.ADVERSE_EVENT
    );
    summary.adverseEvents = aeList && aeList.length > 0;

    let perfList = attributes.filter(
      (a) => a.attributeTypeId === ATTRIBUTE_TYPES.PERFORMANCE
    );
    summary.performance = perfList && perfList.length > 0;

    let cbList = attributes.filter(
      (a) => a.attributeTypeId === ATTRIBUTE_TYPES.CLINICAL_BENEFIT
    );
    summary.clinicalBenefit = cbList && cbList.length > 0;
    return summary;
  }

  useEffect(() => {
    let retryCount = 0;
    const maxRetries = 5;
    const timeout = 30_000; // 30 seconds

    const poll = () => {
      pollApiEndpoint(project.id)
        .then((response) => {
          if (response !== undefined) {
            if (response.codeUpdateStatus === "SUCCESS") {
              if (!disabled) {
                setDisableRefineButton(false);
              }
              setShowCodeUpdateFailureAlert(false);
              setShowPollingTimeoutAlert(false);
              setDeviceSearched(
                deviceSearchError || !response.summary
                  ? { totalPatients: 0 }
                  : response.summary
              );
            } else if (response.codeUpdateStatus === "FAILURE") {
              setDisableRefineButton(true);
              setShowCodeUpdateFailureAlert(true);
            } else if (retryCount < maxRetries) {
              retryCount++;
              pollTimer.current = setTimeout(poll, timeout);
            } else {
              console.error("Polling failed after maximum retries");
              setDisableRefineButton(true);
              setShowPollingTimeoutAlert(true);
            }
          }
        })
        .catch((error) => {
          console.error("Polling error:", error);
          setDisableRefineButton(true);
        });
    };

    if (
      !projectLoading &&
      !!project.id &&
      !deviceLoading &&
      !!deviceData &&
      deviceData.length === 0
    ) {
      poll();
    }
  }, [
    pollApiEndpoint,
    project,
    disabled,
    deviceData,
    deviceLoading,
    deviceSearched,
    deviceSearchError,
    projectLoading,
  ]);

  useEffect(
    () => () => {
      clearTimeout(pollTimer.current);
    },
    []
  );

  return (
    <div className="App">
      <NavBar />
      <div className="details-container">
        {!patientMaxMode && (
          <ProjectHeader
            project={project}
            setSelectedTab={setSelectedTab}
            refreshData={fetchProjectData}
            updateData={updateProject}
            selectedTab={selectedTab}
            disabled={disabled}
            patientsLoading={deviceLoading || summariesLoading}
            patientsError={summariesStatus !== null && summariesStatus !== 200}
            disableRefineButton={disableRefineButton}
            showCodeUpdateFailureAlert={showCodeUpdateFailureAlert}
            showPollingTimeoutAlert={showPollingTimeoutAlert}
          />
        )}
        <Divider />
        {selectedTab === 0 &&
          project.version === projectVersions.previewMode && (
            <PreviewModeOverview
              project={project}
              deviceSearched={deviceSearched}
            />
          )}
        {selectedTab === 0 &&
          project.version !== projectVersions.previewMode && (
            <Overview project={project} deviceSearched={deviceSearched} />
          )}
        {selectedTab === 1 && (
          <CurrentProject.Provider
            value={{
              patientSummaries,
              updatePatientSummaries,
              getImdrfEnabled,
              attributeTypes: project.attributeTypes,
              projectSettings: project.projectSettings,
              defaultPreIndexDays: project.defaultPreIndexDays,
              defaultPostIndexDays: project.defaultPostIndexDays,
            }}
          >
            <CurrentPatient.Provider
              value={{
                indexEncounter,
                setIndexEncounter,
                isIndexEncounterUpdated,
                setIsIndexEncounterUpdated,
                isEncountersUpdated,
                setIsEncountersUpdated,
                encounters,
                setEncounters,
                updateEncounters,
                isProblemsUpdated,
                setIsProblemsUpdated,
                isOtherDevicesUsedUpdated,
                setIsOtherDevicesUsedUpdated,
                isDiagnosisCodesUpdated,
                setIsDiagnosisCodesUpdated,
                isProcedureCodesUpdated,
                setIsProcedureCodesUpdated,
                patient,
                setPatient,
                patientLoading,
                setPatientLoading,
                patientProblems,
                setPatientProblems,
                otherDevicesUsed,
                setOtherDevicesUsed,
                procedureCodes,
                setProcedureCodes,
                diagnosisCodes,
                setDiagnosisCodes,
                isPromsUpdated,
                setIsPromsUpdated,
                setPatientAttributes,
                orderLevelDocuments,
                setOrderLevelDocuments,
              }}
            >
              <Patients
                patientMaxMode={patientMaxMode}
                setPatientMaxMode={setPatientMaxMode}
                patientSummaries={patientSummaries}
              />
            </CurrentPatient.Provider>
          </CurrentProject.Provider>
        )}
        <Footer />
      </div>
    </div>
  );
}
