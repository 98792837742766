import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  LinearProgress,
  Link,
  Modal,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import { getDiffDaysMessage } from "../../../utils/main";
import { projectsPrefix } from "../../../services/ProjectsServices";
import { useFetch } from "../../../services/hooks/useFetch";
import { useSearchParams } from "react-router-dom";
import EmptyCard from "./EmptyCard";
import NoteText from "./NoteText";
import DOMPurify from "dompurify";
import { CurrentPatient } from "../ProjectDetails";
import theme from "../../../styles/theme";
import OfflineBoltOutlinedIcon from "@mui/icons-material/OfflineBoltOutlined";
import InfoIcon from "@mui/icons-material/Info";

export default function OrderLevelDocumentsCard({ categorize }) {
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get("projectId");
  const { patient, orderLevelDocuments, setOrderLevelDocuments } =
    useContext(CurrentPatient);
  const [documentModal, setDocumentModal] = useState(false);
  const [document, setDocument] = useState(null);
  const {
    fetch: fetchDocuments,
    isLoading: isDocumentsLoading,
    error: fetchDocumentsError,
  } = useFetch();

  const {
    fetch: fetchDocument,
    isLoading: isDocumentLoading,
    error: fetchDocumentError,
  } = useFetch();

  const orderLevelDocsDataTableFields = [
    { field: "documentType", headerName: "Document Type", width: 100 },
    {
      field: "diffDays",
      headerName: "Days Pre/Post Index",
      width: 100,
      valueGetter: (params) => getDiffDaysMessage(params.value),
    },
    {
      field: "documentPreview",
      headerName: "Content",
      width: 175,
      renderCell: (params) => {
        const sanitiziedText = DOMPurify.sanitize(params.row.documentPreview, {
          ALLOWED_TAGS: ["highlight"],
        }).replaceAll("\n", "<br />");

        const finalText =
          sanitiziedText.length >= 100
            ? `${sanitiziedText}...`
            : sanitiziedText;
        return (
          <Link
            sx={{ textAlign: "left" }}
            component="button"
            variant="subtitle2"
            onClick={() => {
              handleDocumentModal(params.row);
            }}
            dangerouslySetInnerHTML={{ __html: finalText }}
          />
        );
      },
    },
    {
      field: "priority",
      renderHeader: () => (
        <div className="MuiDataGrid-columnHeaderTitle">
          <Tooltip
            title="Visits with a priority icon may include evidence of an adverse event, clinical or performance failure as identified by the 3Aware system."
            placement="top-end"
            className="priority"
          >
            Priority
            <InfoIcon size="small" sx={{ color: theme.palette.info.main }} />
          </Tooltip>
        </div>
      ),
      renderCell: (params) => {
        return (
          params.row.keywordScore && (
            <OfflineBoltOutlinedIcon sx={{ color: theme.palette.info.main }} />
          )
        );
      },
      align: "center",
      width: 85,
    },
  ];

  function closeDocumentModal() {
    setDocumentModal(false);
  }

  function handleDocumentModal(document) {
    setDocumentModal(true);
    setDocument(document);
    fetchDocument(
      `${projectsPrefix}/${projectId}/patients/${patient.id}/document/${document.documentId}`,
      (data) => {
        if (data != null) {
          setDocument(data);
        }
      }
    );
  }

  useEffect(() => {
    if (fetchDocumentsError) {
      console.log("Error: " + fetchDocumentsError);
    }
    if (fetchDocumentError) {
      console.log("Error: " + fetchDocumentError);
    }
  }, [fetchDocumentsError, fetchDocumentError]);

  useEffect(() => {
    if (!orderLevelDocuments && !isDocumentsLoading && !fetchDocumentsError) {
      // fetch all order-level docs w/ preview only for patient
      fetchDocuments(
        `${projectsPrefix}/${projectId}/patients/${patient.id}/documents`,
        (data) => {
          if (data == null || data.length === 0) {
            setOrderLevelDocuments([]);
          } else {
            // show order-level docs only if patient has at least 1 returned
            data.sort((a, b) => a.diffDays - b.diffDays);
            setOrderLevelDocuments(data);
          }
        }
      );
    }
  }, [
    orderLevelDocuments,
    setOrderLevelDocuments,
    fetchDocuments,
    fetchDocumentsError,
    isDocumentsLoading,
    patient.id,
    projectId,
  ]);

  return (
    <Card className="visit-drawer-card order-level-card">
      <CardContent>
        {!!orderLevelDocuments && orderLevelDocuments.length === 0 ? (
          <EmptyCard type="Order-Level Documents/PDFs" />
        ) : (
          <>
            <Typography variant="subtitle1">
              Order-Level Documents/PDFs
            </Typography>
            <Box className={"order-level-card-contents"}>
              <DataGridPremium
                className={"order-level-table"}
                initialState={{
                  pagination: { paginationModel: { pageSize: 25 } },
                }}
                hideFooter={true}
                loading={isDocumentsLoading}
                slots={{
                  loadingOverlay: LinearProgress,
                }}
                getRowHeight={() => "auto"}
                density="compact"
                getRowId={() => crypto.randomUUID()}
                columns={orderLevelDocsDataTableFields}
                rows={orderLevelDocuments || []}
              />
            </Box>
          </>
        )}
        {documentModal && (
          <Modal open={documentModal} onClose={closeDocumentModal}>
            <Box
              className={"order-level-modal"}
              sx={{ bgcolor: "background.paper" }}
            >
              <Stack
                direction="column"
                spacing={1.5}
                divider={<Divider flexItem />}
                className={"order-level-modal-contents"}
              >
                <Typography variant="h6">{document.documentType}</Typography>
                <Stack
                  direction="column"
                  className={"order-level-modal-text"}
                  spacing={1.5}
                >
                  <Stack direction="row" spacing={1.5}>
                    <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                      Days Pre/Post Index:
                    </Typography>
                    <Typography variant="body2">
                      {getDiffDaysMessage(document.diffDays)}
                    </Typography>
                  </Stack>
                  {isDocumentLoading ? (
                    <>
                      <Typography variant="h6">
                        Loading Full Order Level Document
                      </Typography>
                      <Skeleton
                        animation={"wave"}
                        variant="rounded"
                        height={"100%"}
                      />
                    </>
                  ) : (
                    <NoteText
                      text={document.documentText}
                      variant={"body2"}
                      className={"order-level-modal-text-scroll"}
                    />
                  )}
                </Stack>
                <Stack
                  direction="row"
                  spacing={1}
                  className={"order-level-modal-footer"}
                >
                  <Button
                    size="small"
                    variant="outlined"
                    onClick={closeDocumentModal}
                  >
                    Close
                  </Button>
                  <Button size="small" variant="outlined" onClick={categorize}>
                    Categorize Patient
                  </Button>
                </Stack>
              </Stack>
            </Box>
          </Modal>
        )}
      </CardContent>
    </Card>
  );
}
