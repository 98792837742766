import FetchUtils from "../utils/FetchUtils";
import { deviceApiPrefix } from "../utils/main";

export async function EmailDomain(domain) {
  const path = `${deviceApiPrefix}/devices/manufacturers/domains/exists/${domain}`;
  if (domain === "") {
    return "404";
  }

  try {
    const response = await FetchUtils.fetch(path, "GET");
    return await response.status;
  } catch (error) {
    console.error("Error: ", error.toString());
    return error;
  }
}
